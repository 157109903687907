import { type LatLng } from "@/domain/core/entities/lat_lng"
import { AttendanceRounder } from "@/domain/features/company/v2/entities/attendance/attendance_rounder"
import { type WorkerAttendanceStatus } from "@/domain/features/company/v2/entities/worker_readable/worker_readable"
import { max, min } from "date-fns"

import { type Attendance } from "../attendance/attendance"
import { type CompanyAttendanceSettings } from "../company_attendance_settings/company_attendance_settings"

/**
 * 打刻数の制御
 *
 * 悲観的ロックで、
 * 打刻開始 => 打刻終了 => 打刻開始 => 打刻終了
 * と、交互に作動させるためのドキュメント
 */
export interface AttendanceOperation {
  checkInOperation: CheckInOperation
  // 本来、ここのフィールドは boolean で事足りるが、運用のしやすさを考えて Timestamp を保存する
  checkedOutAt: Date | undefined
}

/**
 * 打刻開始の記録
 */
export interface CheckInOperation {
  checkedInAt: Date
  checkInLocation: LatLng | undefined
  breaktimeMinutes: number
  companyAttendanceSettings: CompanyAttendanceSettingsAtCheckedIn | undefined
  needsHolidayWorkApplication: boolean
  scheduledAttendance: Attendance | undefined
  forceSchedule: boolean
}

/**
 * 打刻開始時点のユーザーの設定
 */
export type CompanyAttendanceSettingsAtCheckedIn = Omit<
  CompanyAttendanceSettings,
  "updatedAt" | "closingDay"
>

export namespace AttendanceOperation {
  export namespace firestore {
    export const documentPath = ({
      userId,
      companyId,
    }: {
      userId: string
      companyId: string
    }) => {
      return `Company/v2/users/${userId}/workingCompanies/${companyId}/attendance/doc/operation/doc`
    }
    export type ParameterOfDocumentPath = Parameters<typeof documentPath>[0]
  }

  export function round(attendanceOperation: AttendanceOperation | undefined): {
    checkedInAt: Date | undefined
    checkedOutAt: Date | undefined
  } {
    if (attendanceOperation == null) {
      return { checkedInAt: undefined, checkedOutAt: undefined }
    }

    const { checkInOperation } = attendanceOperation

    // 企業の設定から、分単位の まるめ を実行
    const rounder = new AttendanceRounder({
      checkInRoundingUpMinutes:
        checkInOperation.companyAttendanceSettings?.checkInRoundingUpMinutes,
      checkOutRoundingDownMinutes:
        checkInOperation.companyAttendanceSettings?.checkOutRoundingDownMinutes,
    })

    let checkedInAt =
      checkInOperation.checkedInAt == null
        ? undefined
        : rounder.roundCheckedInAt(checkInOperation.checkedInAt)
    let checkedOutAt =
      attendanceOperation?.checkedOutAt == null
        ? undefined
        : rounder.checkedOutAt(attendanceOperation?.checkedOutAt)

    const { forceSchedule, scheduledAttendance } = checkInOperation

    // 分単位のまるめとは別で
    // forceSchedule === true の時、打刻時間の矯正を行う
    if (forceSchedule && scheduledAttendance != null) {
      if (checkedInAt != null) {
        checkedInAt = max([checkedInAt, scheduledAttendance.checkedInAt])
      }

      if (checkedOutAt != null) {
        checkedOutAt = min([checkedOutAt, scheduledAttendance.checkedOutAt])
      }
    }

    return { checkedInAt, checkedOutAt }
  }

  export function roundCheckIn(checkInOperation: CheckInOperation) {
    const rounder = new AttendanceRounder({
      checkInRoundingUpMinutes:
        checkInOperation.companyAttendanceSettings?.checkInRoundingUpMinutes,
      checkOutRoundingDownMinutes:
        checkInOperation.companyAttendanceSettings?.checkOutRoundingDownMinutes,
    })

    let checkedInAt = rounder.roundCheckedInAt(checkInOperation.checkedInAt)
    if (
      checkInOperation.forceSchedule &&
      checkInOperation.scheduledAttendance != null
    ) {
      checkedInAt = max([
        checkedInAt,
        checkInOperation.scheduledAttendance.checkedInAt,
      ])
    }

    return checkedInAt
  }

  export type OperationStatus = "checkOut" | "checkIn"

  export function getOperationStatus(
    attendanceOperation: AttendanceOperation | undefined
  ): OperationStatus {
    // attendanceOperation が存在しない
    //   => チェックインを行ったことがない
    if (attendanceOperation == null) {
      return "checkIn"
    }

    // attendanceOperation が存在していて、lastCheckedOutAt が null
    //   => チェックインは行ったことがあるが、チェックアウトは行ったことがない
    if (attendanceOperation.checkedOutAt == null) {
      return "checkOut"
    } else return "checkIn"
  }

  export function getAttendanceStatus(
    attendanceOperation: AttendanceOperation | undefined
  ): WorkerAttendanceStatus {
    switch (getOperationStatus(attendanceOperation)) {
      case "checkOut":
        return "working"
      case "checkIn":
        return "notWorking"
    }
  }
}
