"use client"

import { useEffect, useState, useTransition } from "react"
import Image from "next/image"
import { kTimeZone } from "@/common/constants/constants"
import { cn } from "@/common/utils/utils"
import { type AttendanceConfirmedRecord } from "@/domain/features/company/v2/entities/attendance_confirmed_record/attendance_confirmed_record"
import { AttendanceOperation } from "@/domain/features/company/v2/entities/attendance_operation/attendance_operation"
import {
  MultipleCheckInSettings,
  type CompanyAttendanceSettings,
} from "@/domain/features/company/v2/entities/company_attendance_settings/company_attendance_settings"
import { type WorkerAttendanceStatus } from "@/domain/features/company/v2/entities/worker_readable/worker_readable"
import { AttendanceOperationRepository } from "@/domain/features/company/v2/repositories/attendance_operation_repository"
import { type Schedule } from "@/domain/features/company/v2/use_cases/fetch_day_of_schedule"
import { getFirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/client"
import { getFirebaseFunctionsInfra } from "@/infrastructure/firebase_functions/client"
import { ToastAction } from "@radix-ui/react-toast"
import { format, isSameDay } from "date-fns"
import { Loader } from "lucide-react"

import { Badge } from "@/app/_components/atoms/badge"
import { Button } from "@/app/_components/atoms/button"
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/app/_components/atoms/card"
import TimeBorder from "@/app/_components/atoms/TimeBorder"
import { errorToast, useToast } from "@/app/_components/atoms/use-toast"
import { useCurrentLocationAtom } from "@/app/_hooks/useCurrentLocationAtom"
import { useCurrentUser } from "@/app/_hooks/useCurrentUser"

import { AttendanceScheduleDialog } from "./AttendanceScheduleDialog"
import WorkNoteForm from "./WorkNoteForm"

type Props = {
  className?: string
  schedule: Schedule
  companyId: string
  attendanceConfirmedRecord: AttendanceConfirmedRecord | undefined
  note?: string | null
  companyAttendanceSettings: CompanyAttendanceSettings | undefined
  attendanceOperation: AttendanceOperation | undefined
}

const RawAttendanceCard = ({
  className,
  schedule,
  companyAttendanceSettings,
  note,
  attendanceConfirmedRecord,
  companyId,
  attendanceOperation: initialAttendanceOperation,
}: Props) => {
  const [attendanceOperation, setAttendanceOperation] = useState(
    initialAttendanceOperation
  )
  const { userId } = useCurrentUser()

  useEffect(() => {
    const firestore = getFirebaseFirestoreInfra()

    if (userId == null) {
      return () => {}
    }
    const unsubscribe = new AttendanceOperationRepository(firestore).onSnapshot(
      { userId, companyId },
      (data) => {
        setAttendanceOperation(data)
      }
    )

    return () => {
      unsubscribe()
    }
  }, [companyId, userId])

  const checkInRoundingUpMinutes =
    attendanceOperation?.checkInOperation.companyAttendanceSettings
      ?.checkInRoundingUpMinutes ??
    companyAttendanceSettings?.checkInRoundingUpMinutes ??
    0
  const checkOutRoundingDownMinutes =
    attendanceOperation?.checkInOperation.companyAttendanceSettings
      ?.checkOutRoundingDownMinutes ??
    companyAttendanceSettings?.checkOutRoundingDownMinutes ??
    0

  let { checkedInAt, checkedOutAt } =
    AttendanceOperation.round(attendanceOperation)

  // 今日ではない日の打刻の場合、非表示にする
  if (checkedInAt != null && checkedOutAt != null) {
    if (!isSameDay(checkedInAt, new Date())) {
      checkedInAt = undefined
      checkedOutAt = undefined
    }
  }

  const isLimitOver = MultipleCheckInSettings.isLimitOver(
    companyAttendanceSettings?.multipleCheckInSettings,
    (attendanceConfirmedRecord?.attendances ?? []).length
  )

  const { currentLocationAtom, loadCurrentLocationAtom } =
    useCurrentLocationAtom()

  const [isCheckedInPending, startCheckedInTransition] = useTransition()
  const [isCheckedOutPending, startCheckedOutTransition] = useTransition()

  const { toast } = useToast()

  const handleCheckedIn = () => {
    loadCurrentLocationAtom()

    const position = currentLocationAtom.data

    if (position == null) {
      toast({
        variant: "destructive",
        title: "位置情報が取得できませんでした",
      })

      return
    }

    startCheckedInTransition(async () => {
      try {
        const firebaseFunctions = getFirebaseFunctionsInfra()

        await firebaseFunctions.checkIn({
          location: { latitude: position.lat, longitude: position.lng },
          companyId,
        })

        toast({
          variant: "default",
          title: "出勤打刻が完了しました",
          action: (
            <ToastAction asChild altText="details">
              <a
                href="https://driglo.notion.site/4eb85be345524901ac64733c404530fd"
                target="_blank"
                rel="noopener noreferrer"
              >
                詳しく
              </a>
            </ToastAction>
          ),
        })
      } catch (error) {
        console.error(error)

        errorToast(error)
      }
    })
  }

  const handleCheckedOut = () => {
    const position = currentLocationAtom.data

    if (position == null) {
      toast({
        variant: "destructive",
        title: "位置情報が取得できませんでした",
      })

      return
    }
    loadCurrentLocationAtom()
    startCheckedOutTransition(async () => {
      try {
        const firebaseFunctions = getFirebaseFunctionsInfra()

        await firebaseFunctions.checkOut({
          location: { latitude: position.lat, longitude: position.lng },
          companyId,
        })

        toast({
          variant: "default",
          title: "打刻が完了しました。",
          action: (
            <ToastAction asChild altText="details">
              <a
                href="https://driglo.notion.site/4eb85be345524901ac64733c404530fd"
                target="_blank"
                rel="noopener noreferrer"
              >
                詳しく
              </a>
            </ToastAction>
          ),
        })
      } catch (error) {
        console.error(error)

        errorToast(error)
      }
    })
  }

  const getAttendanceScheduleLabel = () => {
    if (schedule.attendances.length > 1) {
      return <AttendanceScheduleDialog attendances={schedule.attendances} />
    }
    if (schedule.alternativeHolidayType) {
      switch (schedule.alternativeHolidayType) {
        case "substituted":
          return (
            <div className="text-sm text-muted-foreground md:text-base">
              振替休日
            </div>
          )
        case "compensatory":
          return (
            <div className="text-sm text-muted-foreground md:text-base">
              代休
            </div>
          )
      }
    }
    if (schedule.attendanceType === "absence") {
      return (
        <div className="text-sm font-semibold text-[#EB6E66] md:text-base">
          欠勤
        </div>
      )
    }

    const paidLeaveType = attendanceConfirmedRecord?.paidLeaveMetadata?.unit
    switch (paidLeaveType) {
      case "allDay":
        return (
          <div className="text-sm font-semibold text-[#73C7E2] md:text-base">
            有休
          </div>
        )
      case "halfDay":
        return (
          <div className="text-sm font-semibold text-[#73C7E2] md:text-base">
            半休
          </div>
        )
      case "hours":
        return (
          <div className="text-sm font-semibold text-[#73C7E2] md:text-base">
            時間休
          </div>
        )
    }
  }

  const attendanceStatus: WorkerAttendanceStatus | undefined =
    AttendanceOperation.getAttendanceStatus(attendanceOperation ?? undefined)

  return (
    <div>
      <Card className={className}>
        <CardHeader className="flex shrink-0 flex-col items-center justify-between gap-2 md:gap-6">
          <TimeBorder />
          <div className="flex items-center gap-2">
            <Badge
              variant="default"
              className="rounded-md bg-border text-xs font-medium text-muted-foreground hover:bg-border md:text-sm"
            >
              本日の予定
            </Badge>
            {schedule.attendances.length > 0 ? (
              <div
                className={cn("text-sm text-muted-foreground lg:text-base", {
                  "text-secondary": schedule.forceSchedule,
                })}
              >
                {format(schedule.attendances[0]!.checkedInAt, "HH:mm")} ~{" "}
                {format(schedule.attendances[0]!.checkedOutAt, "HH:mm")}
              </div>
            ) : (
              <div className="text-sm text-muted-foreground md:text-base">
                なし
              </div>
            )}
            {getAttendanceScheduleLabel()}
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <div className="flex h-7 items-center justify-center">
                <Image src="/sunny.svg" alt="sunny" width={24} height={24} />
              </div>
              <div className="h-6">
                <p className="text-center text-xl leading-6">
                  {checkedInAt && !isSameDay(new Date(), checkedInAt) && (
                    <span>{format(checkedInAt, "M/d")} </span>
                  )}
                  {checkedInAt ? (
                    checkedInAt?.toLocaleTimeString("ja-JP", {
                      hour: "2-digit",
                      minute: "2-digit",
                      timeZone: kTimeZone,
                    })
                  ) : (
                    <span className="text-center  text-xl leading-5">
                      --:--
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="flex h-7 items-center justify-center">
                <Image
                  src="/nightlight.svg"
                  alt="nightlight"
                  width={24}
                  height={24}
                />
              </div>
              <div className="h-6">
                <p className="text-center text-xl leading-6">
                  {checkedInAt && !isSameDay(new Date(), checkedInAt) && (
                    <span>{format(checkedInAt, "M/d")} </span>
                  )}
                  {checkedOutAt ? (
                    checkedOutAt?.toLocaleTimeString("ja-JP", {
                      hour: "2-digit",
                      minute: "2-digit",
                      timeZone: kTimeZone,
                    })
                  ) : (
                    <span className="text-center text-xl leading-5">--:--</span>
                  )}
                </p>
              </div>
            </div>
          </div>

          {(attendanceStatus === "working" ||
            attendanceStatus === "notWorking") && <WorkNoteForm note={note} />}
        </CardHeader>
        <CardContent className="flex-1 p-0 lg:p-6" />
        <CardFooter className="flex flex-col items-center justify-between gap-2 md:gap-4 lg:gap-6">
          <div className="text-center text-sm text-muted-foreground lg:text-base">
            {attendanceStatus === "notWorking" &&
              checkInRoundingUpMinutes !== 0 &&
              !isLimitOver && (
                <p>
                  出勤打刻は{checkInRoundingUpMinutes}分単位で切り上げられます。
                </p>
              )}
            {attendanceStatus === "working" &&
              checkOutRoundingDownMinutes !== 0 && (
                <p>
                  退勤打刻は{checkOutRoundingDownMinutes}
                  分単位で切り捨てられます。
                </p>
              )}
            {isLimitOver && <p>本日の勤務は終了しました。</p>}
          </div>

          <div className="flex w-full gap-4">
            <Button
              size={"lg"}
              variant={"secondary"}
              className="flex h-16 flex-1 px-0 text-2xl md:h-20 md:text-3xl"
              disabled={attendanceStatus === "working" || isLimitOver}
              onClick={handleCheckedIn}
            >
              <div className="flex items-center justify-center">
                <Image
                  src="/sunny-filled.svg"
                  alt="sunny-filled"
                  width={32}
                  height={32}
                  className="mr-2 flex items-center justify-center pt-2"
                />
                <p className="text-xl leading-8 md:text-3xl md:font-semibold">
                  出勤
                </p>
              </div>
            </Button>
            <Button
              size={"lg"}
              className="flex h-16 flex-1 px-0 text-2xl md:h-20 md:text-3xl"
              disabled={attendanceStatus === "notWorking" || isLimitOver}
              onClick={handleCheckedOut}
            >
              <div className="flex items-center justify-center">
                <Image
                  src="/moon-filled.svg"
                  alt="moon-filled"
                  width={32}
                  height={32}
                  className="mr-2 flex items-center justify-center pt-1"
                />
                <p className="text-xl md:text-3xl md:font-semibold">退勤</p>
              </div>
            </Button>
          </div>
        </CardFooter>
      </Card>
      {isCheckedInPending || isCheckedOutPending ? (
        <div className="fixed inset-0 z-40 flex items-center justify-center bg-foreground/40 md:pl-60">
          <Loader size={80} color="white" className="animate-spin" />
        </div>
      ) : null}
    </div>
  )
}

export default RawAttendanceCard
