import { type DayOfYear } from "../../../../../core/entities/day_of_year"

/**
 * 企業の打刻設定
 */
export interface CompanyAttendanceSettings {
  updatedAt: Date
  checkInRoundingUpMinutes: RoundingMinutes | undefined
  checkOutRoundingDownMinutes: RoundingMinutes | undefined
  closingDay?: number
  multipleCheckInSettings?: MultipleCheckInSettings
  overtimeSettings?: OvertimeSettings
  thirtySixthAgreementSettings?: ThirtySixthAgreementSettings
}

/**
 * 残業設定
 */
export interface OvertimeSettings {
  applicationSystemEnable: boolean
}

export namespace OvertimeSettings {}

export type RoundingMinutes = null | undefined | 0 | 5 | 10 | 15 | 20 | 25 | 30

/**
 * 複数回打刻の設定
 */
export interface MultipleCheckInSettings {
  enable: boolean
  isUnlimited?: boolean
  max?: number
}

export namespace MultipleCheckInSettings {
  export function label(settings: MultipleCheckInSettings | undefined) {
    if (settings == null || !settings.enable) {
      return "1回"
    }
    if (settings.isUnlimited) {
      return "上限なし"
    }

    return `${settings.max ?? 1}回`
  }

  export function isLimitOver(
    settings: MultipleCheckInSettings | undefined,
    count: number
  ) {
    if (settings == null || !settings.enable) {
      return count > 1
    }
    if (settings.isUnlimited) {
      return false
    }

    return count > (settings.max ?? 1)
  }

  /**
   * 管理者ユーザーが設定できる選択肢
   */
  export const selectionItems: MultipleCheckInSettings[] = [
    {
      enable: false,
    },
    {
      enable: true,
      max: 2,
    },
    {
      enable: true,
      max: 3,
    },
    {
      enable: true,
      max: 4,
    },
    {
      enable: true,
      max: 5,
    },
    {
      enable: true,
      isUnlimited: true,
    },
  ]
}

export interface SpecialProvisionSettings {
  manage: boolean
  maxExceedingCount: number | undefined | null
  legalOvertimeMonthlyHoursLimit: number | undefined | null
  legalOvertimeAnnualHoursLimit: number | undefined | null
}

export namespace SpecialProvisionSettings {
  export const defaultSettings = {
    manage: false,
    maxExceedingCount: 6,
    legalOvertimeMonthlyHoursLimit: 99,
    legalOvertimeAnnualHoursLimit: 720,
  } as const satisfies Required<SpecialProvisionSettings>
}

export interface ThirtySixthAgreementSettings {
  startingDate: DayOfYear
  legalOvertimeMonthlyHoursLimit: number
  legalOvertimeAnnualHoursLimit: number
  specialProvisionSettings: SpecialProvisionSettings
}

export namespace ThirtySixthAgreementSettings {
  export const defaultSettings = {
    startingDate: {
      month: 1,
      day: 1,
    },
    legalOvertimeMonthlyHoursLimit: 45,
    legalOvertimeAnnualHoursLimit: 360,
    specialProvisionSettings: SpecialProvisionSettings.defaultSettings,
  } as const satisfies Required<ThirtySixthAgreementSettings>

  export function annualHoursLimit(
    thirtySixthAgreementSettings: ThirtySixthAgreementSettings | undefined
  ): number {
    if (thirtySixthAgreementSettings == null) {
      return defaultSettings.legalOvertimeAnnualHoursLimit
    }

    if (thirtySixthAgreementSettings.specialProvisionSettings.manage) {
      return (
        thirtySixthAgreementSettings.specialProvisionSettings
          ?.legalOvertimeAnnualHoursLimit ??
        defaultSettings.specialProvisionSettings.legalOvertimeAnnualHoursLimit
      )
    } else {
      return (
        thirtySixthAgreementSettings.legalOvertimeAnnualHoursLimit ??
        defaultSettings.legalOvertimeAnnualHoursLimit
      )
    }
  }
  export function monthlyHoursLimit(
    thirtySixthAgreementSettings: ThirtySixthAgreementSettings | undefined
  ): number {
    if (thirtySixthAgreementSettings == null) {
      return defaultSettings.legalOvertimeMonthlyHoursLimit
    }

    if (thirtySixthAgreementSettings.specialProvisionSettings.manage) {
      return (
        thirtySixthAgreementSettings.specialProvisionSettings
          ?.legalOvertimeMonthlyHoursLimit ??
        defaultSettings.specialProvisionSettings.legalOvertimeMonthlyHoursLimit
      )
    } else {
      return (
        thirtySixthAgreementSettings.legalOvertimeMonthlyHoursLimit ??
        defaultSettings.legalOvertimeMonthlyHoursLimit
      )
    }
  }
}

export namespace CompanyAttendanceSettings {
  export namespace firestore {
    export const documentPath = ({ companyId }: { companyId: string }) =>
      `Company/v2/companies/${companyId}/attendance/doc/settings/doc`
    export type ParameterOfDocumentPath = Parameters<typeof documentPath>[0]
  }

  export namespace MultipleCheckInSettings {
    export const defaultSettings = {
      enable: false,
    } as const satisfies MultipleCheckInSettings

    export function isValid({
      currentAttendancesCount,
      multipleCheckInSettings,
    }: {
      multipleCheckInSettings: MultipleCheckInSettings
      currentAttendancesCount: number
    }): boolean {
      // 複数回の設定がない || enable が false の場合、
      if (multipleCheckInSettings == null || !multipleCheckInSettings.enable) {
        return currentAttendancesCount < 1
      }

      const { isUnlimited, max } = multipleCheckInSettings
      if (isUnlimited === true) {
        return true
      }

      // isUnlimited が false の場合は、必ず limit に数字が入っている運用をする
      // もし null が入っている場合は、例外処理として 1 回までを制限とする
      if (max == null) {
        return currentAttendancesCount < 1
      } else {
        return currentAttendancesCount < max
      }
    }
  }

  export namespace OvertimeSettings {
    export const defaultSettings: Required<OvertimeSettings> = {
      applicationSystemEnable: false,
    }
  }

  export const defaultSettings: Required<CompanyAttendanceSettings> = {
    closingDay: 0,
    checkInRoundingUpMinutes: 0,
    checkOutRoundingDownMinutes: 0,
    updatedAt: new Date(),
    multipleCheckInSettings: MultipleCheckInSettings.defaultSettings,
    overtimeSettings: OvertimeSettings.defaultSettings,
    thirtySixthAgreementSettings: ThirtySixthAgreementSettings.defaultSettings,
  }
}
