import { type FirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/core/firestore"
import { type Unsubscribe } from "firebase/auth"
import { getDoc, onSnapshot } from "firebase/firestore"

import { AttendanceOperation } from "../entities/attendance_operation/attendance_operation"

export class AttendanceOperationRepository {
  constructor(private readonly firestore: FirebaseFirestoreInfra) {}

  private documentReference(
    params: AttendanceOperation.firestore.ParameterOfDocumentPath
  ) {
    return this.firestore.documentReference<AttendanceOperation>(
      AttendanceOperation.firestore.documentPath(params)
    )
  }

  onSnapshot(
    params: AttendanceOperation.firestore.ParameterOfDocumentPath,
    onData: (data: AttendanceOperation | undefined) => void
  ): Unsubscribe {
    return onSnapshot(this.documentReference(params), (snapshot) => {
      onData(snapshot.data())
    })
  }

  async fetch(
    params: AttendanceOperation.firestore.ParameterOfDocumentPath
  ): Promise<AttendanceOperation | undefined> {
    const snapshot = await getDoc(this.documentReference(params))

    return snapshot.data()
  }
}
