import { useCallback, useEffect } from "react"
import { useJsApiLoader } from "@react-google-maps/api"
import { atom, useAtom } from "jotai"

import type AsyncValue from "@/common/types/AsyncValue"

// 現在地の取得をする際の初期値
//
// atom()内で定義すると、無限ループするため一旦外で定義
const initialCurrentLocationAtom = atom<AsyncValue<google.maps.LatLngLiteral>>({
  data: null,
  isLoading: true,
  isError: false,
})

export const useCurrentLocationAtom = () => {
  const [currentLocationAtom, setCurrentLocationAtom] = useAtom(
    initialCurrentLocationAtom
  )

  // GoogleAPIをロードする
  //
  // useEffectで成功か非成功かを監視し、状態に応じて成功時と失敗時の処理をわける
  const { isLoaded: isApiLoaded, loadError: hasApiLoadError } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
    region: "JP",
    language: "ja",
  })

  const loadCurrentLocationAtom = useCallback(() => {
    // ブラウザでない場合やApiのロードが完了していない場合
    if (typeof navigator === "undefined" || !isApiLoaded) {
      return
    }

    // 位置情報取得以前にApiのロード自体に失敗している場合
    if (hasApiLoadError) {
      setCurrentLocationAtom({
        data: null,
        isLoading: false,
        isError: true,
      })

      return
    }

    // 現在の位置情報の取得
    //
    // Androidの場合は高精度な位置情報を利用している
    // 元がそういう設定であったため引き継いでいるが、なぜ Android だけなのかは不明
    const enableHighAccuracy = /Android/.test(navigator.userAgent)
    navigator.geolocation.getCurrentPosition(
      // successCallback
      (position) => {
        const currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }

        setCurrentLocationAtom({
          data: currentLocation,
          isLoading: false,
          isError: false,
        })
      },
      // errorCallback
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          console.error(
            "An error occurred while acquiring location information:",
            error.message
          )
        }
        console.error(error)

        setCurrentLocationAtom({
          data: null,
          isLoading: false,
          isError: true,
        })
      },
      // options
      { enableHighAccuracy }
    )
  }, [hasApiLoadError, isApiLoaded, setCurrentLocationAtom])

  // このhookを利用するコンポーネントがマウントされた際に位置情報を取得する
  useEffect(() => {
    loadCurrentLocationAtom()
  }, [loadCurrentLocationAtom])

  return {
    currentLocationAtom,
    loadCurrentLocationAtom,
  }
}
