"use client"

import { useTransition } from "react"
import { workNoteSchema } from "@/common/validations"
import { editAttendanceMemoAtom } from "@/domain/features/company/v2/use_cases/edit_attendance_memo"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAtom } from "jotai"
import { useForm } from "react-hook-form"
import type { z } from "zod"

import { Button } from "@/app/_components/atoms/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/app/_components/atoms/form"
import { Textarea } from "@/app/_components/atoms/textarea"
import { errorToast, useToast } from "@/app/_components/atoms/use-toast"

type Inputs = z.infer<typeof workNoteSchema>

type Props = {
  note?: string | null
  disabled?: boolean
}

const WorkNoteForm = ({ note, disabled }: Props) => {
  const [isPending, startTransition] = useTransition()
  const { toast } = useToast()
  const [, editAttendanceMemo] = useAtom(editAttendanceMemoAtom)

  const form = useForm<Inputs>({
    resolver: zodResolver(workNoteSchema),
    values: {
      note: note ?? "",
    },
  })

  const onSubmit = (data: Inputs) => {
    startTransition(async () => {
      try {
        await editAttendanceMemo({ note: data.note, date: new Date() })

        toast({
          variant: "default",
          title: "保存しました",
        })
        form.reset(
          { ...data },
          {
            keepErrors: true,
            keepDirty: false,
            keepIsSubmitted: false,
            keepTouched: false,
            keepIsValid: false,
            keepSubmitCount: false,
          }
        )
      } catch (error) {
        console.error(error)

        errorToast(error)
      }
    })
  }

  return (
    <div className="w-full max-w-[334px]">
      <Form {...form}>
        <form
          onSubmit={(...arg) => void form.handleSubmit(onSubmit)(...arg)}
          className="space-y-2"
        >
          <FormField
            control={form.control}
            name="note"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <Textarea
                    className="min-h-[40px] w-full lg:min-h-[80px]"
                    style={{ width: "100%" }}
                    maxLength={100}
                    placeholder="勤務メモ（最大100文字）"
                    {...field}
                    disabled={disabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-end">
            <Button
              disabled={
                isPending || disabled || form.formState.isDirty === false
              }
              type="submit"
              size={"sm"}
            >
              保存
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}

export default WorkNoteForm
