"use client"

import React from "react"
import { type Attendance } from "@/domain/features/company/v2/entities/attendance/attendance"
import { format } from "date-fns"
import { ja } from "date-fns/locale"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/app/_components/atoms/dialog"

interface AttendanceScheduleDialogProps {
  attendances: Attendance[]
}

export const AttendanceScheduleDialog = ({
  attendances,
}: AttendanceScheduleDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="text-sm text-muted-foreground md:text-base">
          他{attendances.length}件...
        </button>
      </DialogTrigger>
      <DialogContent className="w-96">
        <DialogHeader className="space-y-4">
          <DialogTitle>本日の予定</DialogTitle>
          <DialogDescription className="flex flex-col space-y-2">
            {attendances.map((attendance, index) => (
              <div key={index} className="flex items-center justify-between">
                <div className="text-sm font-medium">勤務{index + 1}</div>
                <div className="flex items-center gap-1">
                  <div className="text-base font-normal">
                    {format(attendance.checkedInAt, "H:mm", {
                      locale: ja,
                    })}
                  </div>
                  <div>~</div>
                  <div>
                    {format(attendance.checkedOutAt, "H:mm", {
                      locale: ja,
                    })}
                  </div>
                </div>
              </div>
            ))}
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
