"use client"

import React, { useState } from "react"
import { type UserProfile } from "firebase/auth"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/app/_components/atoms/dialog"
import MyNameForm from "@/app/_components/organisms/forms/MyNameForm"

type Props = {
  isFirstTime: boolean
  userProfile: UserProfile | undefined
}

const FirstTimeDialog = ({ isFirstTime, userProfile }: Props) => {
  const isProfileNameEmpty =
    userProfile == null ||
    userProfile.firstName == null ||
    userProfile.lastName == null

  const shouldOpen = () => {
    if (isFirstTime) {
      return true
    }

    return isProfileNameEmpty
  }
  const [isOpen, setIsOpen] = useState(shouldOpen())

  return (
    <>
      {isOpen && (
        <Dialog
          open={isOpen}
          modal
          onOpenChange={
            isProfileNameEmpty
              ? () => {}
              : (isOpen) => {
                  if (!isOpen) {
                    setIsOpen(false)
                  }
                }
          }
        >
          <DialogTrigger className="hidden" />
          <DialogContent suppressHydrationWarning>
            <DialogHeader>
              <DialogTitle>ようこそ！</DialogTitle>
              {isProfileNameEmpty ? (
                <DialogDescription>
                  まずは名前を入力しましょう。名前は後で変更できます。
                </DialogDescription>
              ) : null}
            </DialogHeader>
            {isProfileNameEmpty ? (
              <MyNameForm
                handleModalClose={() => {
                  setIsOpen(false)
                }}
              />
            ) : null}
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default FirstTimeDialog
