import { type Reference } from "@/domain/core/entities/reference"
import { type AttendanceType } from "@/domain/features/company/v2/entities/attendance_type/attendance_type"
import {
  type HalfDayPaidLeavePeriod,
  type PaidLeaveUnit,
} from "@/domain/features/company/v2/entities/paid_leave_unit/paid_leave_unit"
import { format } from "date-fns"

import {
  type AlternativeHolidayType,
  type HolidayType,
} from "../attendance_application/attendance_application"
import { type Attendance } from "../attendance/attendance"

/**
 * 記録としての打刻
 */
export interface AttendanceConfirmedRecord {
  attendances: Attendance[] | undefined
  attendanceType: AttendanceType | undefined
  schedule: AttendanceConfirmedSchedule | undefined
  memo: string | undefined
  pendingApplication: Reference | undefined
  holidayWorkMetadata:
    | {
        holidayType: HolidayType
        // undefined の場合、休日出勤として扱う
        alternativeHolidayType: AlternativeHolidayType | undefined
        alternativeHoliday: Date | undefined
      }
    | undefined
  alternativeHolidayMetadata:
    | {
        alternativeHolidayType: AlternativeHolidayType
        holidayType: HolidayType
        workDate: Date | undefined
      }
    | undefined
  paidLeaveMetadata: PaidLeaveMetadata | undefined
}

/**
 * 有休に関する取得情報
 */
export interface PaidLeaveMetadata {
  unit: PaidLeaveUnit
  hours: number | undefined
  period: HalfDayPaidLeavePeriod | undefined
}

export type AttendanceConfirmedSchedule = {
  attendances: Attendance[] | undefined
  forceSchedule: boolean
}

export namespace AttendanceConfirmedRecord {
  export namespace firestore {
    export const documentPath = ({
      companyId,
      userId,
      date,
    }: {
      companyId: string
      userId: string
      date: Date
    }) => {
      return `Company/v2/users/${userId}/workingCompanies/${companyId}/attendance/doc/days/${documentId(date)}/confirmedRecord/doc`
    }
    export type ParameterOfDocumentPath = Parameters<typeof documentPath>[0]

    export function documentId(date: Date) {
      return format(date, "yyyy-MM-dd")
    }
  }

  const uncontrollableDayOffTypes: AttendanceType[] = [
    "absence",
    "paidLeave",
    "alternativeHoliday",
  ]

  export function isUncontrollableDayOff(
    record: AttendanceConfirmedRecord
  ): boolean {
    if (record.attendanceType == null) {
      return false
    }

    return isDayOffType(record.attendanceType)
  }

  export function isUncontrollableDayOffType(type: AttendanceType): boolean {
    return uncontrollableDayOffTypes.includes(type)
  }

  const dayOffTypes: AttendanceType[] = [
    "legalHoliday",
    "companySpecificHoliday",
    ...uncontrollableDayOffTypes,
  ]

  export function isDayOff(record: AttendanceConfirmedRecord): boolean {
    if (record.attendanceType == null) {
      return false
    }

    return isDayOffType(record.attendanceType)
  }

  export function isDayOffType(type: AttendanceType): boolean {
    return dayOffTypes.includes(type)
  }
}
