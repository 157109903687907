import { AttendanceConfirmedRecordRepository } from "@/domain/features/company/v2/repositories/attendance_confirmed_record_repository"
import { WorkingCompanyRepository } from "@/domain/features/company/v2/repositories/working_company_repository"
import { getFirebaseAuthInfra } from "@/infrastructure/firebase_auth/client"
import { getFirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/client"
import { atom } from "jotai"

import type { AttendanceConfirmedRecord } from "../entities/attendance_confirmed_record/attendance_confirmed_record"

export const attendanceConfirmedRecordAtom = atom<
  AttendanceConfirmedRecord | undefined
>(undefined)

export const editAttendanceMemoAtom = atom(
  null,
  async (get, set, { note, date }: { note: string; date: Date }) => {
    try {
      const firestore = getFirebaseFirestoreInfra()
      const userId = getFirebaseAuthInfra().currentUser?.uid

      if (userId == null) {
        return
      }

      const { companyId } = await new WorkingCompanyRepository(
        firestore
      ).fetchRequired({
        userId,
      })

      await new AttendanceConfirmedRecordRepository(firestore).save({
        params: {
          userId,
          date,
          companyId,
        },
        data: { memo: note },
      })

      const updateRecord = await new AttendanceConfirmedRecordRepository(
        firestore
      ).fetch({
        companyId,
        userId,
        date,
      })

      set(attendanceConfirmedRecordAtom, updateRecord)
    } catch (error) {
      console.error("Failed to update memo for attendance record 🚨🚨", error)
    }
  }
)
