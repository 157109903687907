import { buttonVariants } from "@/app/_components/atoms/button"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/app/_components/molecules/alert-dialog"

type Props = {
  open: boolean
  onClose: () => void
}

const LocationPermissionDialog = ({ open, onClose }: Props) => {
  return (
    open && (
      <AlertDialog open={open}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              位置情報の取得を許可してください。
            </AlertDialogTitle>
            <AlertDialogDescription>
              打刻するにはブラウザの位置情報の取得を許可する必要があります。以下のページを参考に設定を変更してください。
            </AlertDialogDescription>
          </AlertDialogHeader>
          <div className="flex flex-col items-start">
            <a
              href="https://support.google.com/chrome/answer/142065?hl=ja"
              target="_blank"
              rel="noreferrer"
              className={buttonVariants({ variant: "link", size: "sm" })}
            >
              <span className="mr-2 text-black">&#8226;</span>
              Google Chrome
            </a>

            <a
              href="https://support.microsoft.com/ja-jp/microsoft-edge/microsoft-edge-%E3%81%AE%E4%BD%8D%E7%BD%AE%E6%83%85%E5%A0%B1%E3%81%A8%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC-31b5d154-0b1b-90ef-e389-7c7d4ffe7b04"
              target="_blank"
              rel="noreferrer"
              className={buttonVariants({ variant: "link", size: "sm" })}
            >
              <span className="mr-2 text-black">&#8226;</span>
              Microsoft Edge
            </a>

            <a
              href="https://support.mozilla.org/ja/kb/does-firefox-share-my-location-websites#w_saitohenoxu-ke-woqu-rixiao-suniha"
              target="_blank"
              rel="noreferrer"
              className={buttonVariants({ variant: "link", size: "sm" })}
            >
              <span className="mr-2 text-black">&#8226;</span>
              Firefox
            </a>

            <a
              href="https://support.apple.com/ja-jp/guide/mac-help/mh35873/mac"
              target="_blank"
              rel="noreferrer"
              className={buttonVariants({ variant: "link", size: "sm" })}
            >
              <span className="mr-2 text-black">&#8226;</span>
              Safari
            </a>
          </div>
          <AlertDialogFooter>
            <AlertDialogAction onClick={onClose}>OK</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  )
}

export default LocationPermissionDialog
