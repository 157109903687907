import { type RoundingMinutes } from "../company_attendance_settings/company_attendance_settings"

/**
 * 打刻記録のまるめ実行
 */
export class AttendanceRounder {
  constructor(private readonly roundOptions: RoundOptions) {}

  /**
   * チェックイン時間を指定された丸め間隔に基づいて丸めます。
   *
   * @param {Date} checkedInAt - 元のチェックイン時間。
   * @returns {Date} 丸められたチェックイン時間。丸めが不要な場合は、元のチェックイン時間をそのまま返します。
   *
   * @remarks
   * このメソッドは、`checkInRoundingUpMinutes` オプションを使用して、丸め間隔を決定します。
   * `checkInRoundingUpMinutes` が設定されていない、または0以下の場合、元の `checkedInAt` 時間が返されます。
   * `checkedInAt` の分がすでに `checkInRoundingUpMinutes` の倍数であれば、丸めは行われません。
   * それ以外の場合、分は次の `checkInRoundingUpMinutes` の倍数に切り上げられます。
   *
   * @example
   * // checkInRoundingUpMinutes が 15 で、checkedInAt が 08:07 の場合
   * const roundedTime = this.roundCheckedInAt(new Date('2023-10-10T08:07:00'));
   * // roundedTime は 08:15 になります。
   */
  roundCheckedInAt(checkedInAt: Date): Date {
    const { checkInRoundingUpMinutes } = this.roundOptions

    // 丸めが必要ない場合（丸めの間隔が設定されていないか、0の場合）、元の時間を返す
    if (!checkInRoundingUpMinutes) {
      return checkedInAt
    }

    // 現在の分を取得し、丸めが必要かどうかを確認
    const currentMinutes = checkedInAt.getMinutes()
    const remainder = currentMinutes % checkInRoundingUpMinutes

    // 余りがない場合、丸めは必要ない
    if (remainder === 0) {
      return checkedInAt
    }

    // 丸めた時間を計算
    const roundedMinutes =
      Math.ceil(currentMinutes / checkInRoundingUpMinutes) *
      checkInRoundingUpMinutes

    const roundedDate = new Date(checkedInAt)
    roundedDate.setMinutes(roundedMinutes)

    return roundedDate
  }

  checkedOutAt(checkedOutAt: Date): Date {
    const { checkOutRoundingDownMinutes } = this.roundOptions

    if (checkOutRoundingDownMinutes != null) {
      const checkedOutAtDate = new Date(checkedOutAt)

      // minutes を再計算して、setMinutes で上書き
      const minutes = this.floor(
        checkedOutAtDate.getMinutes(),
        checkOutRoundingDownMinutes
      )
      checkedOutAtDate.setMinutes(minutes)

      checkedOutAt = checkedOutAtDate
    }

    return checkedOutAt
  }

  protected floor(num: number, radix: number) {
    if (radix === 0) return num

    let str = num.toString(radix)
    str = str.substring(0, str.length - 1) + "0"

    return parseInt(str, radix)
  }
}

/** まるめ設定 */
export interface RoundOptions {
  checkInRoundingUpMinutes: RoundingMinutes | undefined
  checkOutRoundingDownMinutes: RoundingMinutes | undefined
}
