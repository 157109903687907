import { AttendanceConfirmedRecord } from "@/domain/features/company/v2/entities/attendance_confirmed_record/attendance_confirmed_record"
import { type FirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/core/firestore"
import { type PartialWithFieldValue } from "firebase-admin/firestore"
import { getDoc, setDoc } from "firebase/firestore"

export class AttendanceConfirmedRecordRepository {
  constructor(private readonly firestore: FirebaseFirestoreInfra) {}

  private documentReference(
    params: AttendanceConfirmedRecord.firestore.ParameterOfDocumentPath
  ) {
    return this.firestore.documentReference<AttendanceConfirmedRecord>(
      AttendanceConfirmedRecord.firestore.documentPath(params)
    )
  }

  async save({
    params,
    data,
  }: {
    params: AttendanceConfirmedRecord.firestore.ParameterOfDocumentPath
    data: PartialWithFieldValue<AttendanceConfirmedRecord>
  }) {
    await setDoc(this.documentReference(params), data, { merge: true })
  }

  async fetch(
    params: AttendanceConfirmedRecord.firestore.ParameterOfDocumentPath
  ): Promise<AttendanceConfirmedRecord | undefined> {
    const snapshot = await getDoc(this.documentReference(params))

    return snapshot.data()
  }
}
