"use client"

import { useTransition } from "react"
import { usePathname, useRouter } from "next/navigation"
import { nameSchema } from "@/common/validations"
import { UserProfileRepository } from "@/domain/features/company/v2/repositories/user_profile_repository"
import { getCurrentUserId } from "@/infrastructure/firebase_auth/client"
import { getFirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { serverTimestamp } from "firebase/firestore"
import { Loader } from "lucide-react"
import { useForm } from "react-hook-form"
import type { z } from "zod"

import { Button } from "@/app/_components/atoms/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/app/_components/atoms/form"
import { Input } from "@/app/_components/atoms/input"
import { useToast } from "@/app/_components/atoms/use-toast"

type Inputs = z.infer<typeof nameSchema>

type Props = {
  firstName?: string
  lastName?: string
  handleModalClose: () => void
}

const MyNameForm = ({ firstName, lastName, handleModalClose }: Props) => {
  const [isPending, startTransition] = useTransition()
  const { toast } = useToast()

  const form = useForm<Inputs>({
    resolver: zodResolver(nameSchema),
    defaultValues: {
      lastName: lastName ?? "",
      firstName: firstName ?? "",
    },
  })
  const router = useRouter()
  const companyId = usePathname().split("/")[1] as string

  const onSubmit = ({ firstName, lastName }: Inputs) => {
    startTransition(async () => {
      try {
        const firestore = getFirebaseFirestoreInfra()
        const repository = new UserProfileRepository(firestore)

        const userId = getCurrentUserId()!

        await repository.save({
          params: { companyId, userId },
          data: {
            firstName,
            lastName,
            updatedAt: serverTimestamp(),
          },
        })

        toast({
          variant: "default",
          title: "名前を編集しました。",
        })
        handleModalClose()
        router.refresh()
      } catch (error) {
        console.error(error)
        toast({
          variant: "destructive",
          title: "予期しないエラーが発生しました",
        })
      }
    })
  }

  return (
    <Form {...form}>
      <form
        className="grid gap-4"
        onSubmit={(...arg) => void form.handleSubmit(onSubmit)(...arg)}
      >
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>姓 (Last name)</FormLabel>
              <FormControl>
                <Input max={30} {...field} type="text" placeholder="山田" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>名 (First name)</FormLabel>
              <FormControl>
                <Input max={30} {...field} type="text" placeholder="太郎" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          disabled={isPending}
          type="submit"
          className="w-fit justify-self-end"
        >
          {isPending && (
            <Loader
              size={16}
              color="white"
              className="mr-2 animate-spin"
              aria-hidden="true"
            />
          )}
          保存
        </Button>
      </form>
    </Form>
  )
}

export default MyNameForm
