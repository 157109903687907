"use client"

import React, { useState } from "react"
import { type UserAvatarIcon } from "@/domain/features/company/v2/entities/user_profile/user_profile"
import { GoogleMap, MarkerF } from "@react-google-maps/api"

import { Spinner } from "@/app/_components/atoms/Spinner"
import { getUserAvatarIconPath } from "@/app/_components/organisms/image/UserAvatarIconView"
import { useCurrentLocationAtom } from "@/app/_hooks/useCurrentLocationAtom"
import { useWindowSize } from "@/app/_hooks/useWindowSize"

import LocationPermissionDialog from "./LocationPermissionDialog"

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
}

type Props = {
  avatarIcon?: UserAvatarIcon
}

const GoogleMapSheet = ({
  // AvatarIcon マーカーの処理実装後に使用
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  avatarIcon,
}: Props) => {
  const { isDesktop } = useWindowSize()

  const { currentLocationAtom } = useCurrentLocationAtom()

  const [showAlertDialog, setShowAlertDialog] = useState(
    currentLocationAtom.isError
  )

  if (currentLocationAtom.isLoading) {
    return (
      <div
        style={mapContainerStyle}
        className="flex items-center justify-center"
      >
        <Spinner className="size-8 animate-spin" />
      </div>
    )
  }

  return (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={16}
        center={
          // 画面中央よりやや左に寄せる
          currentLocationAtom.data
            ? {
                lat: currentLocationAtom.data.lat + (isDesktop ? 0 : -0.0024),
                lng: currentLocationAtom.data.lng + (isDesktop ? 0.004 : 0),
              }
            : undefined
        }
        options={{
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
      >
        {currentLocationAtom.data && (
          <MarkerF
            position={currentLocationAtom.data}
            icon={{
              url: getUserAvatarIconPath(avatarIcon),
              scaledSize: new google.maps.Size(80, 80),
            }}
          />
        )}
      </GoogleMap>
      <LocationPermissionDialog
        open={showAlertDialog}
        onClose={() => {
          setShowAlertDialog(false)
          window.location.reload()
        }}
      />
    </>
  )
}

export default GoogleMapSheet
