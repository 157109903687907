"use client"

import React, { useEffect, useState } from "react"
import { cn } from "@/common/utils/utils"
import { format } from "date-fns"
import { ja } from "date-fns/locale"

const TimeBorder = () => {
  const [currentDate, setCurrentDate] = useState(Date.now())

  useEffect(() => {
    const updateCurrentDate = () => {
      setCurrentDate(Date.now())
    }

    updateCurrentDate()

    const interval = setInterval(() => {
      updateCurrentDate()
    }, 100)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div
      className={cn(
        "w-full flex-col items-center justify-center sm:gap-6 md:pt-4"
      )}
    >
      <div
        className={cn(
          "text-center text-base font-normal md:text-xl md:font-medium lg:text-2xl"
        )}
      >
        {format(currentDate, "M月d日 (E)", { locale: ja })}
      </div>
      <div className="text-center text-4xl font-medium tabular-nums md:text-5xl md:font-semibold lg:text-7xl">
        {format(currentDate, "HH:mm", { locale: ja })}
        <span className="ml-1 text-xl md:text-4xl">
          {format(currentDate, "ss", { locale: ja })}
        </span>
      </div>
    </div>
  )
}

export default TimeBorder
